
/* App.css */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}



main {
  margin-top: 64px; /* Adjust this value if your AppBar height is different */
}

